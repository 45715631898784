<template lang="pug">
div(v-if='Entrada' )
    //-PC
    div.d-none.d-md-flex
        v-container(v-if='Object.keys(Entrada).length>0' fluid)
            v-row.ml-4.mt-12
            v-row.mr-4.ml-4.mt-3
            v-row.mt-2.mr-4.ml-4.justify-center
                v-img( gradient="to bottom left, rgba(180, 50, 36, 0.45) , transparent" :src='Entrada.portada' height='470' width='1290')
                    v-container(fluid).fill-height
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                        v-row.mt-12
                            v-spacer
                            div(v-if='Entrada.subtítulo.length>0')
                                v-card(v-if='(Entrada.título.length+Entrada.subtítulo.length)>=52' 
                                height='90px' width='450px' align='center' justify='center').black.pr-4.elevation-0.card.pt-3
                                    h3.white--text {{Entrada.título}} - {{Entrada.subtítulo}}
                                v-card(v-else height='90px' width='450px' align='center' justify='center').black.pr-4.elevation-0.card.pt-3
                                    h3.white--text {{Entrada.título}} - {{Entrada.subtítulo}}
                            div(v-else)
                                v-card(height='70px' width='450px' align='center' justify='center').black.pr-4.elevation-0.card.pt-3
                                    h3.white--text {{Entrada.título}}
                        v-row
                            h2.font-weight-light.white--text.pl-3.ml-4.mb-5 {{Entrada.creado_el|fecha_a_letras}}
            v-row.mt-5.ml-1
                v-col(cols=6)
                    h1 {{Entrada.título}}
            v-row.mt-1.ml-1.mr-2
                v-col(cols=12)
                    span(v-html='Entrada.contenido').text-justify
            v-row.mt-8.ml-1
                v-col(cols=5).pa-0.pr-4.ml-3
                    v-img(:src='Entrada.imágenes[índice_actual]' :width='735.6' :height='471.89').pr-5
                v-col(cols=6).pa-0
                    v-container(fluid).pa-0.ma-0
                        v-row.pa-0.ma-0
                            v-col(cols=4 v-for='imagen, i in Entrada.imágenes' :key='i' @click='parar=true, índice_actual=i').pa-0.pr-3.pb-3
                                v-img(v-if='i==índice_actual' :src='imagen' :width='262' :height='157').imagen
                                v-img(v-else :src='imagen' :width='262' :height='157')     
            v-row.pt-7.pa-0
                v-container(fluid)
                    v-row.mt-12.pr-6.pl-6
                        div.rectangulo.secundario.mr-5.mt-2
                        h1.font-weight-medium.mr-2 ENTRADAS RELACIONADAS
            v-row.pr-6.pl-6
                v-container(fluid).pa-0.ma-0
                    v-row.mt-8.pr-1.mt-12.pt-12.pb-5
                        Novedad_item(v-for='entrada, i in Registro_de_entradas' :key='i' :entrada='entrada').pl-4.pr-8.pb-8
    //-Móvil 
    div.d-md-none.pa-0.ma-0
        v-container(v-if='Object.keys(Entrada).length>0').pr-0.pl-0.mr-0
            v-row
                v-divider.negro.mt-6.mb-7       
            v-row.ml-3
                h3.grey--text.lighten-2.font-weight-light Palabras claves
            v-row.mr-4.ml-3.mb-5.my-6
                v-slide-group
                    v-slide-item(v-for='{nombre, orden, id}, i in Registro_de_etiquetas' :key='i' 
                    v-slot:default='{ active, toggle }')
                        v-chip.white--text( elevation='0' color='#CCCCCC' @click='alterar_etiqueta(id)').chip.mr-4
                            span.black--text.pr-3.pl-3 {{nombre}}
            v-row(align='center' justify='center').mt-6.pl-4.pr-4
                v-col
                    v-text-field(placeholder='Buscar' color='#999999' outlined append-icon='fas fa-search')
            v-img(  :src='Entrada.portada' aspect-ratio='2').pa-0.ma-4
                v-container(fluid).fill-height
                    v-row.mt-12.mb-2
                        v-spacer
                        v-card(v-if='(Entrada.título.length+Entrada.subtítulo.length)>=52'  
                        width='300px' height='50px').justify-end.align-end.black.pr-4.elevation-0.card.pt-3
                            body-2.ml-12.white--text {{Entrada.título}} 
                        v-card(v-else height='50px' width='390px' ).justify-end.align-end.negro.elevation-0.card.pt-2
                            p.ml-12.white--text.justify-end.align-end {{Entrada.título}} 
                    v-row.justify-start.align-start.ml-0    
                        span.white--text {{Entrada.creado_el|fecha_a_letras}}
            v-row.mt-5
                h2(style='line-height : 30px').mr-4.ml-7 {{Entrada.título}}
                p.mr-4.ml-7 {{Entrada.subtítulo}}
            v-row.mt-1
                v-container.mr-4.ml-4
                    span(v-html='Entrada.contenido').text-justify
            v-row(align='center' justify='center').mt-2.mr-3.ml-3.my-4
                v-img(:src='Entrada.imágenes[índice_actual]' :width='291' :height='187')
            v-row.mt-2.mr-3.ml-3.my-4   
                v-col(cols=4 v-for='imagen, i in Entrada.imágenes' :key='i' @click='parar=true, índice_actual=i').pa-0
                    v-img(v-if='i==índice_actual' :src='imagen' :width='123' :height='76').imagen
                    v-img(v-else :src='imagen' :width='123' :height='70')     
            //- v-row.pr-3.pl-3
            //-     Novedad_item(v-for='entrada, i in registro_de_entrada' :key='i' :entrada='entrada')
</template>
<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
    data: ()=>({
        índice_actual: 0,
        parar: false,
    }),
    methods: {
        ...mapActions({
            obtener_por_slug_de_blog: 'Blog/obtener_por_slug_de_blog',
            obtener_todas_las_entradas: 'Blog/obtener_todos',
            obtener_por_id_de_blog: 'Blog/obtener_por_id_de_blog'
        }),
        ...mapMutations({
            alterar_etiqueta: 'Etiquetas/alterar_etiqueta',
        }),
        dirigir_a(){
            this.alterar_etiqueta("")
            this.$router.replace({name: 'blog', params: {}})
            this.$vuetify.goTo("#navegador")
        },
    },
    async created(){
        if(this.$route.params.id_entrada) await this.obtener_por_id_de_blog(this.$route.params.id_entrada)
        else if(this.$route.params.slug) await this.obtener_por_slug_de_blog(this.$route.params.slug)
        await setInterval(()=>{
            if(!this.parar){
                if(this.índice_actual==this.Entrada.imágenes.length-1) this.índice_actual=0
                else this.índice_actual = this.índice_actual + 1
            }
        }, 4000)
    },
    computed: {
        ...mapState({
            Entrada: ({Blog})=>Blog.entrada,
        }),
        registro_de_entrada(){
            let id_etiqueta = this.Entrada.etiquetas[0].id
            let arreglo = this.$store.getters['Blog/registro'].filter(({etiquetas})=>etiquetas.some(({id})=>id==id_etiqueta))
            let arreglo_filtrado = arreglo.splice(0, 3)
            return arreglo_filtrado
        },
    },
    components: {
        Novedad_item: ()=>import('../novedades/novedad_item'),
    },
    watch: {
        async '$route.params.id_entrada'(){
            await this.obtener_por_id_de_blog(this.$route.params.id_entrada)
        }
    },
}
</script>
<style lang="sass">
    .chip
        border-radius: 7px!important
        height: 30px!important
    .card
        border-radius: 0px!important
        bottom: 50px!important
    .imagen
        border: 5px solid rgba(180, 50, 36)!important
</style>
<style>
    a {
        color: rgba(180, 50, 36)!important;
    }
</style>
<style lang="sass">
  
    .posicion_primer_separador
        top: 40px!important
        right: 3%!important
    .posicion_primer_separador_movil
        top: 40px!important
        right: 20%!important

</style>
